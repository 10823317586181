import React from 'react';

import CallToAction            from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading                 from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper                 from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import RandomBrandHeaderImages from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import Display                 from '@interness/theme-default/src/components/Display/Display';
import LiveAnnouncements       from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';

function IndexPage(props) {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Cladder</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Herzlich willkommen bei Juwelier Cladder, dem renommierten Fachgeschäft im Herzen von Goch. Mit der Expertise unseres Uhrmachermeisters und Goldschmiedemeisters bieten wir Ihnen in unserer Meisterwerkstatt exklusiven Service und hochwertige Produkte. Unser Sortiment umfasst eine erlesene Auswahl an Uhren, Schmuckstücken, Trauringen und Eheringen, die mit Liebe zum Detail und handwerklicher Präzision gefertigt werden.</p>
            <p>Bei Juwelier Cladder verstehen wir, dass Schmuck und Uhren mehr sind als bloße Accessoires – sie sind Ausdruck Ihrer Persönlichkeit und Begleiter besonderer Lebensmomente. Deshalb legen wir großen Wert auf Qualität, Design und eine individuelle Beratung. Ob Sie auf der Suche nach dem perfekten Verlobungsring sind, ein einzigartiges Schmuckstück wünschen oder eine Uhr, die Ihre Persönlichkeit unterstreicht – bei uns finden Sie, was Ihr Herz begehrt.</p>
            <p>In unserer Meisterwerkstatt kümmern wir uns auch um Reparaturen und Wartungen Ihrer Schmuckstücke und Uhren. Wir verstehen die Bedeutung von Zuverlässigkeit und Sorgfalt im Umgang mit Ihren wertvollen Stücken. Zusätzlich bieten wir einen Altgoldankauf-Service an, bei dem Sie alten Schmuck in neue Träume verwandeln können.</p>
            <p>Besuchen Sie uns in Goch und erleben Sie den Unterschied, den handwerkliches Können und persönlicher Service machen. Juwelier Cladder steht für Tradition und Innovation – ein Ort, wo die Zeit für Sie stehen bleibt und Schönheit in jeder Form gefeiert wird.</p>
            <p>Bis bald in Goch</p>
            <p>Karl-Heinz und Sabine Cladder mit Team</p>
          </span>
        </section>
        <Spacer/>
        <section>
          <Display/>
          <Spacer/>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;
